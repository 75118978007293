import React from "react";


export default function Account({
  address,
  userSigner,
  localProvider,
  mainnetProvider,
  price,
  minimized,
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  blockExplorer,
}) {
  const modalButtons = [];
  if (web3Modal) {
    if (web3Modal.cachedProvider) {
      modalButtons.push(
        <>
          <a className="pageButton" href="###" onClick={logoutOfWeb3Modal}>LOGOUT</a>
        </>,
      );
    } else {
      modalButtons.push(
        <>
          <a className="pageButton" href="###" onClick={loadWeb3Modal}>CONNECT WALLET</a>
        </>,
      );
    }
  }

  return (
    modalButtons
  );
}

