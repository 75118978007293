import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { ethers } from "ethers";
import CountDownTimer from "../components/CountDownTimer";
import { useContractReader, useOnBlock } from "../hooks";
// import ContentLoader from "react-content-loader"
import BNFTPreview from "../assets/bnft/BenFT-example4.jpg"
import Logo from "../assets/bnft/logo.jpg"
import DiscordLogo from "../assets/bnft/icon-ds.gif"
import InstagramLogo from "../assets/bnft/icon-in.gif"
import TwitterLogo from "../assets/bnft/icon-tw.gif"
import BenLogo from "../assets/bnft/benft-title.jpg"
import OpenSeaLogo from "../assets/bnft/icon-os.gif"
import HOME from "../assets/bnft/icon-hm.gif"
import CountUp from 'react-countup';
import Oswald from '../assets/fonts/Oswald.ttf'
import '../assets/bnft/mint.css';
import ethLogo from "../assets/ethereumLogo.png";

const BnftPreviewWrap = styled.div`
  background: url(${BNFTPreview});
  background-size: cover;
  width: 100%;
  height: 47vw;
  @media (max-width: 764px) {
    display: none;
  }
`

const SocialWrap = styled.div`
  @media(max-width: 765px) {
    width: 100%;
  }
`

const SocialImg = styled.img`
  width: 15%;
  @media(max-width: 765px) {
    width: 12%;
  }
`

const PriceWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const LogoImg = styled.img`
  margin-right: 1rem;
`

// need to update this so that its on render like use effect
const isMobile = () => {
  return window.innerWidth <= 700;
};

const Layout = styled.div`
  display: grid;
  position: relative;
  background: black;
  grid-template-columns: 1fr 1fr;
  background-size: cover;
  align-items: center;
  @media (max-width: 764px) {
    grid-template-columns: 1fr;
  }
`;

const CounterInput = styled.input`
  background: transparent;
  border: solid white 2px;
  text-align: center;
  width: 15%;
  margin-right: 2rem;
  margin-left: 2rem;
  font-size: 1rem;
  padding: 1rem;
  @media (max-width: 764px) {
    width: 30%;
  }
`

const CounterBtnWrap = styled.button`
  border: 0;
  background: transparent;
  color: white;
  font-size: 4vw;
  cursor: pointer;
  @media (max-width: 764px) {
    font-size: 10vw;
  }
  ${props => props.left && css`
     
  `}
`

const AmountWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Welcome = styled.div`
  text-transform: uppercase;
  font-size: 3vw;
  @media (max-width: 800px) {
    font-size: 4vw;
  }
`;

const MaxPerWallet = styled.div`
  color: rgb(255 255 255 / 60%);
  font-size: 1.1rem;
  @media (max-width: 764px) {
    font-size: 4vw;
  }
`

const PriceText = styled.div`
  font-size: 2vw;
  @media (max-width: 764px) {
    font-size: 4vw;
  }
`;

const MintBtn = styled.button`
  margin: 2rem 0;
  background: #61c95a !important;
  color: #0F0F0F !important;
  padding: 0.5rem 1.7rem !important;
  cursor: pointer;
  text-transform: uppercase;
  justify-self: self-start;
  border: 0 !important;
  font-size: 1.5vw;
  transition: all .2s;
  &:hover {
    color: #000000 !important;
    background: #FFFFFF !important; 
    transition: all .2s;
  }
`;

const Nav = styled.div`
  padding: 5rem;
`

const NavWrap = styled.div`
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: black;
  text-transform: uppercase;
  font-weight: 600;
  border: solid white 2px;
  @media (max-width: 764px) {
    height: 600px;
    padding: 3rem;
    flex-direction: column;
  }
`;

const NavItem = styled.a`
  background: #03baad;
  font-size: 1.2vw;
  padding: 0.5vw 3vw;
  color: black;
  &:hover {
    color: white;
  }
  @media (max-width: 764px) {
    font-size: 3.2vw;
    padding: 0.5vw 3vw;
    width: 150px;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const FooterSection = styled.div`
  padding: 2rem;
  background: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media(max-width: 765px){
    flex-direction: column;
  }
`;

const CollectionWrap = styled.div`
  padding: 3rem;
`;

const CollectionHeading = styled.h2`
  margin-top: 1.5rem;
  font-size: 2.5vw;
  text-transform: uppercase;
  color: white;
  @media (max-width: 764px) {
    font-size: 5.5vw;
  }
`;

const CollectionListWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3rem;
  margin-top: 2rem;
  @media (max-width: 765px) {
    grid-template-columns: 1fr;
  }
`;

const CollectionImg = styled.img`
  width: 65%;
  border-radius: 1rem;
`;

const CollectionItem = styled.div``;

const CollectionTitle = styled.div`
  font-size: 1vw;
  margin-top: 2rem;
  color: white;
  @media (max-width: 765px) {
    font-size: 3vw;
  }
`;

const CounterWrap = styled.div``;

const SoldFont = styled.div`
  font-size: 1.4rem;
  color: white;
  @media (max-width: 765px) {
    font-size: 2rem;
  }
`;

const MiddleSection = styled.div`
  color: white;
  padding: 4rem;
  display: grid;
  @media(max-width: 765px) {
    margin-bottom: 1rem;
  }
`;

const mintPrice = 0.069;

const GridItem = styled.div`
  align-self: center;
  @media(max-width: 765px) {
    margin-bottom: 1rem;
  }
`

const CopywriteText = styled.div`
  color: white;
  text-align: left;
  @media(max-width: 765px) {
    margin-bottom: 1rem;
  }
`

const FooterLogo = styled.img`
  width: 100%;
`

const FooterLogoLink = styled.a`
  width: 10%;
  @media(max-width: 765px) {
    width: 50%;
    margin-bottom: 1rem;
  }
`

const SocialLink = styled.a`
  margin-left: 1rem;
`

const LoadingFont = styled.div`
  font-size: 1.5vw;
  color: white;
`

export default function BNFT({
  address,
  localProvider,
  userSigner,
  mainnetProvider,
  price,
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  blockExplorer,
  readContracts,
  tx,
  writeContracts,
  getFromIPFS,
}) {
  const [currentTokens, setCurrentTokens] = useState(0);
  const [yourCollectibles, setYourCollectibles] = useState();
  const [isLoading, setLoading] = useState(true);
  const [collectionLoading, setCollectionLoading] = useState(true);
  const [purchaseCounter, setPurchaseCounter] = useState(1);
  const [priceWithCounter, setPriceWithCounter] = useState(mintPrice);

  const maxTokens = 10000;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showStats = () => {
    if(currentTokens){
      if(currentTokens >= 9825) {
        return (
          <>
            <SoldFont>Sold Out</SoldFont>
          </>
        )
      } else {
        console.log("Made it in showStats")
        return (
          <>
            <CountUp end={currentTokens} duration={1} />
            <span>{`/${maxTokens} Sold`}</span>
          </>
        )
      }
    }
  };


  const balance = useContractReader(readContracts, "BenFTCollection", "balanceOf", [address]);

  const yourBalance = balance && balance.toNumber && balance.toNumber();

  useOnBlock(localProvider, async () => {
    if(await readContracts?.BenFTCollection){
      const mintedTokens = (await readContracts.BenFTCollection.totalSupply()).toNumber();
      setCurrentTokens(mintedTokens);
      console.log("minted tokens", mintedTokens);
      setLoading(false);
    }
  });

  
  useEffect(() => {
    const updateYourCollectibles = async () => {
      const collectibleUpdate = [];
      for (let tokenIndex = 0; tokenIndex < balance; tokenIndex++) {
        try {
          console.log("Getting token index", tokenIndex);
          const tokenId = await readContracts.BenFTCollection.tokenOfOwnerByIndex(address, tokenIndex);
          console.log("tokenId", tokenId);
          const tokenURI = await readContracts.BenFTCollection.tokenURI(tokenId);
          console.log("tokenURI", tokenURI);

          const ipfsHash = tokenURI.replace("ipfs://", "");
          console.log("ipfsHash", ipfsHash);

          const jsonManifestBuffer = await getFromIPFS(ipfsHash);
          const obj = JSON.parse(jsonManifestBuffer.toString());
          console.log({ obj });
          try {
            const jsonManifest = JSON.parse(jsonManifestBuffer.toString());
            console.log("jsonManifest", jsonManifest);
            collectibleUpdate.push({
              id: tokenId,
              imageWithPath: obj.image.replace("ipfs://", "https://ipfs.io/ipfs/"),
              owner: address,
              ...jsonManifest,
            });
          } catch (e) {
            console.log(e);
          }
        } catch (e) {
          console.log(e);
        }
      }
      setYourCollectibles(collectibleUpdate);
    };
    updateYourCollectibles();
  }, [address, yourBalance]);
    const showNFTs = () => {
      if(yourCollectibles){
        return yourCollectibles.map(item => {
          const itemId = item.id.toNumber();
          console.log({ itemId });
          return (
            <CollectionItem key={itemId}>
              <a
              target="_blank"
              href={`https://opensea.io/assets/0x343a086b2dff6bbb0a014093684881d105d03fb5/${itemId}`}
              rel="noopener noreferrer"
            >
              <video loop="" controls="" autoPlay="" name="media">
              <source src={item.source}
                      type="video/mp4" />
              </video>
                <CollectionTitle>{item.name}</CollectionTitle>
            </a>
            </CollectionItem>
        );
        })
      }
      return true;
    }
  

  const handlePurchaseInput = (e) => {
    const newValue = e.target.value;
    if(newValue <= 100){
      console.log('new purchase price', newValue);
      setPurchaseCounter(newValue);
      setPriceWithCounter(newValue * mintPrice);
    }
  };

  const handleDecrease = () => {
    if (purchaseCounter > 1) {
      const newCount = purchaseCounter - 1;
      setPurchaseCounter(newCount);
      setPriceWithCounter(newCount * mintPrice);
    }
  };

  const handleIncrease = () => {
    if (purchaseCounter < 100) {
      const newCount = purchaseCounter + 1;
      setPurchaseCounter(newCount);
      setPriceWithCounter(newCount * mintPrice);
    }
  };

    return (
      <>
        <div className="App">          
          <p className="welcome">Introducing 10,000 distinguished Benjamin Franklins with the goal of helping those in need. <br /><span className="pageHighlightColor">70% of proceeds go directly to people living in poverty</span>.<br />Supply: <span className="pageHighlightColor">10,000</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Mint Date: <span className="pageHighlightColor">Nov.&nbsp;6,&nbsp;2021</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Mint Price: <span className="pageHighlightColor">@0.069&nbsp;ETH</span><br />Pre-Sale: <span className="pageHighlightColor">9:00 AM PST</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Public-Sale <span className="pageHighlightColor">12:00 PM PST</span><br/><span className="copyrightNotice">Each BenFT comes with a non-exclusive commercial usage license for the duration of ownership.</span><br /></p>
          <div className="clear"></div>          
          <div className="mintBoxWrapper">            
            <div className="mintBoxLeft">            
              &nbsp;              
            </div>            
            <div className="mintBoxRight">              
              <img className="mobileOnly" src={BNFTPreview} alt=""/>
              <MiddleSection>
                <GridItem>
                  <Welcome>CLAIM YOUR PIECE OF</Welcome>
                  <Welcome>THE BENFT COLLECTION</Welcome>
                  {/* Only show during public sale*/}
                  <MaxPerWallet>100 Max Per Wallet</MaxPerWallet>
                </GridItem>
                <GridItem>
                  <PriceWrap>
                    {/*<LogoImg src={ethLogo} />*/}
                    <PriceText>{parseFloat(priceWithCounter).toFixed(3)} ETH</PriceText>
                  </PriceWrap>
                  <AmountWrap>
                    <CounterBtnWrap onClick={handleDecrease}>
                      -
                    </CounterBtnWrap>
                    <CounterInput value={purchaseCounter} onChange={handlePurchaseInput} />
                    <CounterBtnWrap onClick={handleIncrease}>
                      +
                    </CounterBtnWrap>
                  </AmountWrap>
                  <CounterWrap>
                    <MintBtn
                      onClick={async () => {
                        console.log("GWEI Amount >", (mintPrice * purchaseCounter).toString());
                        if (userSigner) {
                          tx( 
                            writeContracts.BenFTCollection.mintBen(purchaseCounter, {
                              value: ethers.utils.parseUnits((0.069 * purchaseCounter).toString()),
                            }),
                          );
                        } else alert("You forgot to connect your wallet");
                      }}
                    >
                      Mint Now
                    </MintBtn>
                  </CounterWrap>
                </GridItem>
                <GridItem>
                  <CountDownTimer localProvider={localProvider} />
                  {/*<div>Pre-Sale Ends In: <span style={{color: '#03BAAD'}}>10:23:32</span></div>*/}
                  {/*<SoldFont>Sold Out</SoldFont>*/}
                  {!isLoading ? <SoldFont>{showStats()}</SoldFont> : <LoadingFont>Loading...</LoadingFont>}
                </GridItem>
              </MiddleSection>              
            </div>          
          </div>          
          <div className="clear"></div>          
          <div className="collectionWrapper">
            <h2>Your BenFT Collection</h2>
            <div className="collectionRow">
              {yourCollectibles &&
              yourCollectibles.map(item => {
                const itemId = item.id.toNumber();
                console.log({ itemId });
                return (
                  <div className="collectionItem">
                    <a
                      target="_blank"
                      href={`https://opensea.io/assets/0x343a086b2dff6bbb0a014093684881d105d03fb5/${itemId}`}
                      rel="noreferrer"
                    >
                      <CollectionImg src={item.imageWithPath} alt="" />
                      <div className="collectionTokenTitle">{item.name}</div>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>         
          <div className="clear"></div>
          <div className="footer">	           
            <a className="pageButton" target="_blank" href="https://discord.gg/hprUPRq9h7"> DISCORD </a>
            <a className="pageButton" target="_blank" href="https://twitter.com/BenFTClub"> TWITTER </a>
            <a className="pageButton" target="_blank" href="https://www.instagram.com/benftclub/"> INSTAGRAM </a>
            <a className="pageButton" href="https://benft.club/"> PROJECT ROADMAP </a>
            <a className="pageButton" href="https://opensea.io/collection/benft-collection"> OPENSEA COLLECTION </a>
            <a className="pageButton" href="https://etherscan.io/address/0x343a086b2dff6bbb0a014093684881d105d03fb5"> VERIFIED CONTRACT </a>
          </div>
          <div className="credits">
            <p>&copy; 2021, by BENFT.club&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Powered by <a target="_blank" href="http://www.generativenfts.io" rel="noreferrer">GenerativeNFTs.io</a>.</p>
          </div>       
        </div>
      </>
    ) 
}
