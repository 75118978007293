import React from "react";
import Account from "./Account";
import "../assets/bnft/mint.css"
import BenFTitle from "../assets/bnft/benft-logo.png"



export default function Header(
  {
    address,
    localProvider,
    userSigner,
    mainnetProvider,
    web3Modal,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    blockExplorer
  }
) {
  return (
    <>
      <div className="topBanner">
        <div id="saleStatus">BENFT MINTING PAGE</div>            
        <div id="branding"><img className="wide" src={BenFTitle} alt=""/></div>				            
        <div id="navButtons">            
          <a className="pageButton" href="https://benft.club/"> PROJECT HOME</a> 
          <span id="walletButton">
              <Account
                key="walletConnect"
                address={address}
                localProvider={localProvider}
                userSigner={userSigner}
                mainnetProvider={mainnetProvider}
                web3Modal={web3Modal}
                loadWeb3Modal={loadWeb3Modal}
                logoutOfWeb3Modal={logoutOfWeb3Modal}
                blockExplorer={blockExplorer}
              />                    
          </span>                          
        </div>  
      </div>          
      <div className="clear"></div>
    </>
  );
}


